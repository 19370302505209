import FixedFooter from 'components/custom/onboarding/fixed-footer';
import { getIn, useFormikContext } from 'formik';
import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  GetCountiresListDocument,
  JobLocationTypeEnum,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import { RouterButton } from '@libs/ui/components/button';
import {
  ConnectedGraphSelect,
  ConnectedSelect,
} from '@libs/ui/components/form/select';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import { LOCATION_TYPE_OPTIONS } from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

import { InfoBox } from '../../shared/InfoBox';
import { useDefaultOnSubmitHandler } from '../form-context/hooks';
import { BuilderFormState } from '../form-context/types';
import NextButtonWithValidation from '../shared/NextButtonWithValidation';
import StepTemplate from '../shared/StepTemplate';
import { DefaultStepProps } from '../shared/types';

interface WorkspaceProps extends DefaultStepProps {}

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 430,
    margin: '0 auto',
  },
  info: {
    marginTop: theme.spacing(4),
    textAlign: 'left',
  },
}));

const Workspace = ({ index, nextStepRoute, prevStepRoute }: WorkspaceProps) => {
  const classes = useStyles();
  const { values } = useFormikContext<BuilderFormState>();
  const location = getIn(
    values,
    modelPath<BuilderFormState>((m) => m.workspace.location),
  );
  const { onSubmit, loading } = useDefaultOnSubmitHandler({
    redirectRoute: nextStepRoute,
  });

  return (
    <StepTemplate
      title="Workspace"
      subtitle="What are you looking for?"
      currentStep={index}
      currentStepLabel="Location"
    >
      <Box className={classes.form}>
        <ConnectedSelect
          name={modelPath<BuilderFormState>((m) => m.workspace.location)}
          hideNoneValue
          options={LOCATION_TYPE_OPTIONS}
          variant="filled"
          label="Location"
          fullWidth
        />

        {location !== JobLocationTypeEnum.Remote && (
          <>
            <Box pt={4}>
              <ConnectedGraphSelect
                query={GetCountiresListDocument}
                dataPath="getCountriesList"
                hideNoneValue
                dataMap={{ text: 'name', value: 'name' }}
                fullWidth
                queryOptions={{ variables: { is_europe: true } }}
                label="Country"
                name={modelPath<BuilderFormState>((m) => m.workspace.country)}
                variant="filled"
                formControlProps={{ size: 'small' }}
              />
            </Box>

            <Box pt={4}>
              <ConnectedTextField
                name={modelPath<BuilderFormState>((m) => m.workspace.city)}
                variant="filled"
                label="City"
                fullWidth
              />
            </Box>
          </>
        )}

        {location === JobLocationTypeEnum.Remote && (
          <InfoBox className={classes.info}>
            <Typography textAlign="left" variant="caption">
              Remote is the ‘default’ state for all jobs on the OpenTalent
              Marketplace. Our community prefers remote jobs.
            </Typography>
          </InfoBox>
        )}
      </Box>

      <FixedFooter>
        <Grid spacing={4} justifyContent="center" container>
          <Grid xs={6} item>
            <RouterButton
              startIcon={<ArrowBackIcon />}
              to={prevStepRoute}
              color="primary"
              variant="outlined"
              fullWidth
            >
              Back
            </RouterButton>
          </Grid>
          <Grid xs={6} item>
            <NextButtonWithValidation
              path={modelPath<BuilderFormState>((m) => m.workspace)}
              disabled={loading}
              onClick={onSubmit}
            >
              next
            </NextButtonWithValidation>
          </Grid>
        </Grid>
      </FixedFooter>
    </StepTemplate>
  );
};

export default Workspace;
