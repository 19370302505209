import { mergeDeep } from '@apollo/client/utilities';
import { Formik } from 'formik';
import { usePushWithQuery } from 'hooks/routing';
import React, { useCallback } from 'react';

import { Grid } from '@mui/material';

import {
  CompanyPoolingTypeEnum,
  GetCountiresListDocument,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import FormikAutoSaving from '@libs/ui/components/form/formik/FormikAutoSave';
import {
  ConnectedGraphSelect,
  ConnectedSelect,
} from '@libs/ui/components/form/select';

import { FilterState } from './types';

interface CompaniesFilterProps {
  filter: FilterState;
}

const COMPANIY_TYPE_OPTIONS = [
  CompanyPoolingTypeEnum.Company,
  CompanyPoolingTypeEnum.Recruiter,
].map((option) => ({
  text: `${option.slice(0, 1)}${option.slice(1).toLowerCase()}`,
  value: option,
}));

export const useAutoSaveHandler = () => {
  const push = usePushWithQuery();
  return useCallback(
    (newValues: FilterState) => {
      push({
        query: {
          type: newValues.type,
          country: newValues.country,
          lookingFor: newValues.lookingFor,
        },
      });
    },
    [push],
  );
};

const DEFAULT_FILTER: FilterState = {
  type: '' as unknown as any,
  country: '',
  lookingFor: '',
};

export const CompaniesFilter = ({ filter }: CompaniesFilterProps) => {
  const autoSaveHandler = useAutoSaveHandler();
  const initialValues = mergeDeep(DEFAULT_FILTER, filter);

  return (
    <Formik<FilterState>
      onSubmit={autoSaveHandler}
      initialValues={initialValues}
    >
      <>
        <FormikAutoSaving debounceMs={1000} />
        <Grid container spacing={4}>
          <Grid item>
            <ConnectedSelect
              name={modelPath<FilterState>((m) => m.type)}
              options={COMPANIY_TYPE_OPTIONS}
              fullWidth
              variant="filled"
              label="Type"
              hideNoneValue
            />
          </Grid>
          <Grid item>
            <ConnectedGraphSelect
              query={GetCountiresListDocument}
              dataPath="getCountriesList"
              hideNoneValue
              dataMap={{ text: 'name', value: 'name' }}
              fullWidth
              queryOptions={{ variables: { is_europe: true } }}
              label="Country"
              name={modelPath<FilterState>((m) => m.country)}
              variant="filled"
            />
          </Grid>
          {/* <Grid item> */}
          {/*   <ConnectedSelect */}
          {/*     name={modelPath<FilterState>((m) => m.lookingFor)} */}
          {/*     hideNoneValue */}
          {/*     options={[]} */}
          {/*     fullWidth */}
          {/*     disabled */}
          {/*     variant="filled" */}
          {/*     label="Looking for" */}
          {/*   /> */}
          {/* </Grid> */}
        </Grid>
      </>
    </Formik>
  );
};
