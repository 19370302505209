import { getIn, useFormikContext } from 'formik';
import { useCurrentUser } from 'hooks/auth';
import React from 'react';

import { Box, Typography, Button, Grid } from '@mui/material';

import {
  GetCountiresListDocument,
  useCreateCompanyAccountAccessRequestMutation,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import { ConnectedGraphSelect } from '@libs/ui/components/form/select';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';

import { FormState } from './types';

interface CompanyFormStepProps {
  onNext: () => void;
  onBack: () => void;
}

export const AboutCompanyFormStep = ({
  onNext,
  onBack,
}: CompanyFormStepProps) => {
  const { user } = useCurrentUser();
  const { validateForm, values, errors } = useFormikContext<FormState>();
  const [createCompanyAccountAccessRequest, { loading }] =
    useCreateCompanyAccountAccessRequestMutation({
      onCompleted: () => onNext(),
    });

  const aboutErrors = getIn(
    errors,
    modelPath<FormState>((m) => m.about),
  );
  const hasErrors = Object.keys(aboutErrors || {}).length > 0;

  const onSubmit = async () => {
    const errors = await validateForm();
    const currentErrors = getIn(
      errors,
      modelPath<FormState>((m) => m.company),
    );

    if (Object.keys(currentErrors || {}).length === 0) {
      createCompanyAccountAccessRequest({
        variables: {
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
          email: user?.email,
          new_company_name: values.company?.name?.text || '',
        },
      });
    }
  };

  return (
    <div>
      <Box mb={8}>
        <Typography variant="h5" textAlign="center" paragraph>
          Let's set up your Community
        </Typography>
      </Box>

      <Box>
        <Typography variant="body1" textAlign="center">
          Tell us a bit more about your company.
        </Typography>
      </Box>
      <Box mt={4} mb={6}>
        <ConnectedTextField
          fullWidth
          size="small"
          variant="filled"
          label="Website"
          name={modelPath<FormState>((m) => m.about.website)}
        />
      </Box>
      <Box mt={4} mb={6}>
        <ConnectedGraphSelect
          query={GetCountiresListDocument}
          dataPath="getCountriesList"
          hideNoneValue
          dataMap={{ text: 'name', value: 'name' }}
          fullWidth
          queryOptions={{ variables: { is_europe: true } }}
          label="Country"
          variant="filled"
          formControlProps={{ size: 'small' }}
          name={modelPath<FormState>((m) => m.about.country)}
        />
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Button
            fullWidth
            color="info"
            variant="outlined"
            onClick={onBack}
            size="large"
            disabled={loading}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="info"
            variant="contained"
            fullWidth
            onClick={onSubmit}
            size="large"
            disabled={loading || hasErrors}
          >
            CONTINUE &gt;
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
